<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="年:">
            <el-input v-model="selectForm1.Year" placeholder="年"></el-input>
          </el-form-item>
          <el-form-item label="月:">
            <el-input v-model="selectForm1.Month" placeholder="月"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @row-dblclick="handleEdit"
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
        <el-form ref="SakaryForm"
                 :model="SakaryForm"
                 class="demo-form-inline"
                 label-width="120px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="姓名:" prop="UserName">
                        <el-input type="text"
                                  v-model="SakaryForm.UserName"
                                  :disabled="true">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证" prop="IdCard">
                        <el-input type="text"
                                  v-model="SakaryForm.IdCard"
                                  :disabled="true">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="应出勤天数:" prop="UserAttend">
                        <el-input type="text" v-model="SakaryForm.UserAttend"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="实际出勤天数" prop="UserRealAttend">
                        <el-input type="text" v-model="SakaryForm.UserRealAttend">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="办事处出勤天数:" prop="OutAttend">
                        <el-input type="text" v-model="SakaryForm.OutAttend"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="回公司天数" prop="BackFactoryDay">
                        <el-input type="text" v-model="SakaryForm.BackFactoryDay">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="探亲天数:" prop="BackFamilyDay">
                        <el-input type="text" v-model="SakaryForm.BackFamilyDay">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="请假天数" prop="AskDay">
                        <el-input type="text" v-model="SakaryForm.AskDay"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="值班剩余:" prop="OndutyDay">
                        <el-input type="text" v-model="SakaryForm.OndutyDay"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="加班天数" prop="AddDay">
                        <el-input type="text" v-model="SakaryForm.AddDay"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="夜班天数:" prop="NigtyDay">
                        <el-input type="text" v-model="SakaryForm.NigtyDay"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="探亲补贴" prop="BackFamilySalary">
                        <el-input type="text" v-model="SakaryForm.BackFamilySalary">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="质量补贴:" prop="QualitySalary">
                        <el-input type="text" v-model="SakaryForm.QualitySalary">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="零工" prop="OddSalary">
                        <el-input type="text" v-model="SakaryForm.OddSalary"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="日工资出勤天数:" prop="DayAttend">
                        <el-input type="text" v-model="SakaryForm.DayAttend"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="日工资单价" prop="DaySalary">
                        <el-input type="text" v-model="SakaryForm.DaySalary"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="计件工资:" prop="PieceSalary">
                        <el-input type="text" v-model="SakaryForm.PieceSalary">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="基本工资小计" prop="BasicSalary">
                        <el-input type="text" v-model="SakaryForm.BasicSalary">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="其他补贴:" prop="OtherMoney">
                        <el-input type="text" v-model="SakaryForm.OtherMoney"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="个人欠款" prop="SellSalary">
                        <el-input type="text" v-model="SakaryForm.SellSalary"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="其他扣款:" prop="OtherSalary">
                        <el-input type="text" v-model="SakaryForm.OtherSalary">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上半月已发放" prop="IssuedSalary">
                        <el-input type="text" v-model="SakaryForm.IssuedSalary">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="工人系数:" prop="WorkerMod">
                        <el-input type="text" v-model="SakaryForm.WorkerMod"> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="计件天数" prop="PieceDay">
                        <el-input type="text" v-model="SakaryForm.PieceDay"> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="绩效分数:" prop="PerformanceScore">
                        <el-input type="text" v-model="SakaryForm.PerformanceScore"> </el-input>
                    </el-form-item>
                </el-col>
                <!--<el-col :span="12">
                    <el-form-item label="职能优化绩效:" prop="PositionPerformance">
                        <el-input type="text" v-model="SakaryForm.PositionPerformance"> </el-input>
                    </el-form-item>
                </el-col>-->
            </el-row>
            <el-row>
                <el-col :span="24" style="text-align: center">

                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                </el-col>
            </el-row>
        </el-form>
    </el-dialog>

    <el-dialog
      title="薪资考勤"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
                <!-- 这里触发点击事件 -->

                <el-button type="primary"
                           size="mini"
                           icon="el-icon-search"
                           @click="getInsuranceData()">查询</el-button>
                <el-button size="mini"
                           type="primary"
                           icon="el-icon-edit"
                           :disabled="isDisabled"
                           @click="handleInEdit()">修改</el-button>
                <el-button size="mini"
                           type="primary"
                           icon="el-icon-delete"
                           :disabled="isDisabled"
                           @click="handleInDel()">删除</el-button>
                <el-button size="mini"
                           type="primary"
                           icon="el-icon-download"
                           @click="doDown()">导入模板下载</el-button>
                <!--<el-button size="mini" type="primary">
                    <a target="_blank" href="https://attachment.gradgroup.cn/uc/default/2021-11/3a00145a-1f60-fea2-491d-7b09465571c0.xlsx" download="薪资考勤导入模板">导入模板下载</a>
                </el-button>-->
            </el-form-item>
          </el-form>
          <el-upload
            action="https://api.gradgroup.cn/ftp/ftp/upload"
                     :headers ="token"
            :on-change="handleChange"
            :file-list="fileListUpload"
            :show-file-list="false"
            :limit="1"
            accept=".xlsx,.xls"
            :auto-upload="false"
          >
            <el-button
              :loading="disbtn"
              size="small"
              :disabled="isDisabled"
              type="primary"
              >上传 Excel</el-button
            >
          </el-upload>
        </el-col>
      </el-row>
      <el-table :data="InsuranceData"
                highlight-current-row
                border
                height="500"
                @current-change="selectCurrentRow1"
                @selection-change="handleSelectionChange"
                class="new_table">
          <el-table-column fixed prop="UserName" label="名称"> </el-table-column>
          <el-table-column fixed
                           label="身份证"
                           show-overflow-tooltip
                           width="150"
                           prop="IdCard"></el-table-column>
          <el-table-column label="应出勤天数"
                           width="120"
                           prop="UserAttend"></el-table-column>

          <el-table-column label="实际出勤天数"
                           width="120"
                           prop="UserRealAttend"></el-table-column>
          <el-table-column label="办事处出勤天数"
                           width="120"
                           prop="OutAttend"></el-table-column>
          <el-table-column label="回公司天数"
                           width="120"
                           prop="BackFactoryDay"></el-table-column>
          <el-table-column label="探亲天数"
                           width="120"
                           prop="BackFamilyDay"></el-table-column>
          <el-table-column label="请假天数" prop="AskDay"></el-table-column>
          <el-table-column label="值班剩余" prop="OndutyDay"></el-table-column>
          <el-table-column label="加班天数" prop="AddDay"></el-table-column>
          <el-table-column label="夜班天数" prop="NigtyDay"></el-table-column>
          <el-table-column label="探亲补贴"
                           prop="BackFamilySalary"></el-table-column>
          <el-table-column label="质量补贴"
                           prop="QualitySalary"></el-table-column>
          <!-- <el-table-column label="驻外补贴"  prop="OutsideSalary" ></el-table-column> -->
          <el-table-column label="零工" prop="OddSalary"></el-table-column>
          <el-table-column label="日工资出勤天数"
                           width="120"
                           prop="DayAttend"></el-table-column>
          <el-table-column label="日工资单价"
                           width="120"
                           prop="DaySalary"></el-table-column>
          <el-table-column label="计件工资" prop="PieceSalary"></el-table-column>
          <el-table-column label="基本工资小计"
                           width="120"
                           prop="BasicSalary"></el-table-column>
          <el-table-column label="其他补贴" prop="OtherMoney"></el-table-column>
          <el-table-column label="个人欠款" prop="SellSalary"></el-table-column>
          <el-table-column label="其他扣款" prop="OtherSalary"></el-table-column>
          <el-table-column label="上半月已发放"
                           width="120"
                           prop="IssuedSalary"></el-table-column>
          <el-table-column fixed="right" label="工人系数" prop="WorkerMod"></el-table-column>
          <el-table-column label="计件天数" prop="PieceDay"></el-table-column>
          <el-table-column label="绩效分数" prop="PerformanceScore"></el-table-column>
          <!--<el-table-column label="职能优化绩效" prop="PositionPerformance"></el-table-column>-->
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper ,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  GetSalaryExcelMonthList,
  AddSalaryExcelMonth,
  DeleteSalaryExcelMonth,
  UpdateSalaryExcelMonth,
  GetSalaryExcelList,
  AddSalaryExcel,
  DeleteSalaryExcel,
  UpdateSalaryExcel,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
      return {
          token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      selectForm: {
        userName: "",
        month: "",
        year: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Year: this.selectForm1.Year,
        Month: this.selectForm1.Month,
      };

      GetSalaryExcelMonthList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
      };
      GetSalaryExcelList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.getInsuranceData();
      this.InsuranceVisible = true;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.SakaryVisible = true;
    },

    handleAdd() {
      AddSalaryExcelMonth().then((res) => {
        if (res.data.success) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      DeleteSalaryExcelMonth(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleInDel() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });
        return;
      }

      let para = {
        id: row.Id,
      };

      DeleteSalaryExcel(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });

          this.getInsuranceData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
        UpdateSalaryExcelMonth({ id: row.Id }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    handleDelLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要解锁的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要解锁吗？", "提示", {}).then(() => {
        UpdateSalaryExcelMonth({ id: row.Id, State: "解锁" }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          UpdateSalaryExcel(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    doDown() {
        //let hrefStr = encodeURI("/api/SalaryExcel/DownloadTemplate");
        let hrefStr = encodeURI("https://attachment.gradgroup.cn/uc/default/2021-12/3a00ba33-dc38-2ea3-78a8-fac33c982cc3.xlsx?name=资源文件");
        
      window.location.href = hrefStr;
      },
      
    // 上传附件
      handleChange(file, fileList) {
          
        console.log(this.PId);
        console.log("fileListUpload",this.fileListUpload.clear)
      this.fileTemp = file.raw;
        (this.disbtn = true), this.importfxx(this.fileTemp, this.PId);
        
    },
    // Excel 转化
    importfxx(obj, pid) {
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      var rABS = false; // 是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      // if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; // 是否将文件读取为二进制字符串
        var pt = this;
        var wb; // 读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              // 手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); // outdata就是你想要的东西
          this.da = [...outdata];
          let arr = [];
          this.da.map((v) => {
            //console.log(v);
            let obj = {};
              obj.UserName = v["姓名"];
              obj.UserCode = v["账号"];
            obj.IdCard = v["身份证"];
            obj.UserAttend = v["应出勤天数"];
            obj.UserRealAttend = v["实际出勤天数"];
            obj.OutAttend = v["办事处出勤天数"];
            obj.BackFactoryDay = v["回公司天数"];
            obj.BackFamilyDay = v["探亲天数"];
            obj.AskDay = v["请假天数"];
            obj.OndutyDay = v["值班剩余"];
            obj.AddDay = v["加班天数"];
            obj.NigtyDay = v["夜班天数"];
            obj.BackFamilySalary = v["探亲补贴"];
            obj.QualitySalary = v["质量补贴"];
            obj.OddSalary = v["零工"];
            obj.DayAttend = v["日工资出勤天数"];
            obj.WorkerMod = v["工人系数"];
            obj.PieceDay = v["计件天数"];
            obj.DaySalary = v["日工资单价"];
            obj.PieceSalary = v["计件工资"];
            obj.BasicSalary = v["基本工资小计"];
            obj.OtherMoney = v["其他补贴"];
            obj.SellSalary = v["个人欠款"];
            obj.OtherSalary = v["其他扣款"];
              obj.IssuedSalary = v["上半月已发放"];
              obj.PerformanceScore = v["绩效分数"];
              //obj.PositionPerformance = v["职能优化绩效"];
            //  obj.OutsideSalary = v['驻外补贴']

            obj.PId = pid;
            arr.push(obj);
            _this.list = [...arr];
          });
            console.log(arr)
          _this.ExcelData(arr);
        };
          
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
      ExcelData(arr) {
         // console.log(arr)
      AddSalaryExcel(arr).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "导入成功",
            type: "success",
            duration: 5000,
          });
          this.disbtn = false;
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.masg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

